import { HStack, Text, StyleProps, Image } from "@chakra-ui/react";
import { FC } from "react";
import CustomImage from "~/components/CustomImage";

const Date: FC<
  StyleProps & {
    date?: string;
    noOfLines?: number;
  }
> = ({ date, noOfLines, ...styleProps }) => {
  return (
    <HStack
      pt={styleProps.pt ?? 2}
      spacing={1}
      w="full"
      alignItems="flex-start"
      {...styleProps}
    >
      <CustomImage src="/assets/images/event/calendar.svg" w={4} h={4} />
      {date && (
        <Text fontSize="xs" color="gray.500" noOfLines={noOfLines ?? 2}>
          {date}
        </Text>
      )}
    </HStack>
  );
};

export default Date;
